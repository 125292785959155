import React, { useEffect } from 'react'
import CustomButton from '../global/CustomButton'
import { Link, useLocation, useNavigate } from 'react-router-dom'

const Navbar = () => {
  const location = useLocation()
  const navigate = useNavigate()

  useEffect(() => {
    const elementId = location.hash.substring(1)
    scrollToElement(elementId)
  }, [location])

  const scrollToElement = (elementId: string) => {
    const element = document.getElementById(elementId)
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }
  }

  return (
    <header className="h-[5.5rem] w-full px-10 absolute top-0 xl:px-14 flex items-center justify-between bg-cz360-white z-50">
      <p className="w-fit xl:w-40 text-3xl font-semibold text-cz360-brown-500 font-gangster text-right">CZ360</p>

      <div className="hidden md:flex items-center space-x-4 lg:space-x-16 font-semibold text-base ">
        <Link to={'#OurSkills'}>Co umíme</Link>
        <Link to={'#Products'}>Produkty</Link>
        <Link to={'#Pricing'}>Ceník</Link>
        <Link to={'#Contact'}>Kontakt</Link>
      </div>

      <CustomButton
        classes="w-40 h-11 hidden md:inline-block"
        onClick={() => {
          navigate('#Contact')
        }}
      >
        Kontaktujte nás
      </CustomButton>
    </header>
  )
}

export default Navbar
