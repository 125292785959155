import React, { useMemo } from 'react'

type CustomButtonProps = {
  classes?: string
  type?: 'button' | 'submit' | 'reset'
  variant?: 'brown' | 'orange' | 'text'
  disabled?: boolean
  children?: React.ReactNode
  onClick: () => void
}

const CustomButton: React.FC<CustomButtonProps> = ({
  children,
  variant = 'brown',
  classes = '',
  onClick,
  disabled,
  type = 'button',
}) => {
  const btnClasses = useMemo(() => {
    const hoverClasses = disabled
      ? ['cursor-default', 'opacity-50']
      : ['hover:scale-[0.98]', 'active:scale-90', 'hover:opacity-90', 'cursor-pointer']

    let classArray = ['p-2', 'text-semi-base', 'duration-300', 'ease-out', 'font-bold', ...hoverClasses]

    // .. Variant stylings
    if (variant === 'brown') {
      classArray.push('text-cz360-white', 'bg-cz360-brown-500')
    }

    if (variant === 'orange') {
      classArray.push('text-cz360-white', 'bg-cz360-orange')
    }

    if (variant === 'text') {
      classArray.push('text-cz360-dark', 'bg-transparent')
    }

    // .. Add custom classes from props
    if (classes) {
      classArray.push(classes)
    }

    return classArray.join(' ')
  }, [variant])

  return (
    <button className={btnClasses} onClick={onClick} type={type} disabled={disabled}>
      {children}
    </button>
  )
}

export default CustomButton
