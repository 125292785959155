import React from 'react'
import Navbar from './components/features/Navbar'
import { BrowserRouter } from 'react-router-dom'

import Home from './components/features/Home'
import OurSkills from './components/features/OurSkills'
import GoogleStreetView from './assets/img/GoogleStreetView.svg'
import Gallery from './components/features/Gallery'
import Pricing from './components/features/Pricing'
import ContactUs from './components/features/ContactUs'
import Footer from './components/features/Footer'

function App() {
  return (
    <BrowserRouter>
      <div className="w-screen h-auto min-h-screen overflow-y-auto overflow-x-hidden bg-cz360-white">
        <Navbar />

        <Home />
        <OurSkills />

        <section className="w-full bg-cz360-brown-100 py-[2.75rem] flex justify-center">
          <img src={GoogleStreetView} alt="Google Street View Certified logo" />
        </section>

        <Gallery />
        <Pricing />
        <ContactUs />

        <Footer />
      </div>
    </BrowserRouter>
  )
}

export default App
