import GoogleMaps from '../../assets/img/GoogleMaps.png'
import MapyCz from '../../assets/img/MapyCz.png'
import Matterport from '../../assets/img/Matterport.png'
import DrawingThoughts from '../../assets/img/Drawing-Thoughts.png'

import ApprovedStarGreen from '../../assets/icons/ApprovedStarGreen.svg'
import ApprovedStarOrange from '../../assets/icons/ApprovedStarOrange.svg'

const OurSkills = () => {
  return (
    <section
      id="OurSkills"
      className="border-t w-screen border-cz360-brown-500 min-h-[calc(100vh_+_1px)] px-12 lg:px-16 xl:px-24 py-12 bg-cz360-brown-100 flex flex-col"
    >
      <section className="flex flex-1">
        <div className="w-[45%] flex justify-end">
          <div className="h-1 w-14 sm:w-20 rounded-full bg-cz360-orange mr-4 mt-6 sm:mt-10" />
        </div>

        <h1 className="w-[55%] text-2xl sm:text-4xl  md:text-5xl md:font-semibold font-gangster">Co umíme ? </h1>
      </section>

      <div className="flex justify-center w-full py-10 md:hidden ">
        <img src={DrawingThoughts} alt="Image of thinking" />
      </div>

      <section className="flex flex-1">
        <div className="hidden w-[45%] md:flex flex-col items-end">
          <img src={DrawingThoughts} alt="Image of thinking" className="hidden md:block mr-20 my-auto" />
        </div>

        <div className=" w-full md:w-[55%] flex flex-col items-start">
          <div className="space-y-10  md:space-y-20 my-auto py-10">
            <section>
              <p>
                Získejte další zákazníky pro svou kavárnu, restauraci nebo hotel. Díky tomu můžete oslovit i větší
                skupiny zákazníků jako firemní akce, svatby nebo oslavy narozenin.{' '}
              </p>

              <div className="flex py-6">
                <img src={ApprovedStarOrange} alt="Icon of approved star orange" className="px-4" />
                <p>Samozřejmostí u nás je publikace virtuální prohlídky do Google Maps a Mapy . cz.</p>
              </div>
            </section>

            <section>
              <p>
                Virtuální prohlídky Matterport vám umožní zájemcům projít si každý kout nemovitostí. Ponoří se do
                prostoru, rozhlížejí se kolem sebe a objevují každou místnost a detail bez fyzické přítomnosti.
              </p>

              <div className="flex py-6">
                <img src={ApprovedStarGreen} alt="Icon of approved star green" className="px-4" />
                <p>
                  Matterport prohlídky poskytují realistický a přesný pohled, a zvyšují počet vážných zájemců o
                  nemovitost na prohlídkách.
                </p>
              </div>
            </section>
          </div>
        </div>
      </section>

      <section className="flex items-center flex-col md:flex-row h-fit py-10 gap-6 flex-wrap">
        <img src={GoogleMaps} alt="Img/Icon of GoogleMaps" className="mx-auto" />
        <img src={MapyCz} alt="Img/Icon of MapyCz" className="mx-auto" />
        <img src={Matterport} alt="Img/Icon of Matterport" className="mx-auto" />
      </section>
    </section>
  )
}

export default OurSkills
