import React from 'react'
import CustomButton from '../global/CustomButton'
import PricingCard from '../global/PricingBlock'
import SplashPricingDesktop from '../../assets/img/background/SplashPricingDesktop.svg'
import { useNavigate } from 'react-router-dom'

const Pricing = () => {
  const navigate = useNavigate()

  return (
    <div id="Pricing" className="w-full pt-2 px-12 bg-cz360-brown-300 bg-opacity-[0.33] md:bg-transparent">
      <div
        className="w-full flex flex-col items-center bg-no-repeat bg-contain bg-top min-h-screen "
        style={{ backgroundImage: `url(${SplashPricingDesktop})` }}
      >
        <section className="flex space-x-6 items-center my-14">
          <div className="rounded bg-cz360-orange w-20 h-1" />
          <h1 className="font-gangster text-2xl sm:text-4xl  md:text-5xl">Ceník (ceny jsou bez DPH)</h1>
          <div className="rounded bg-cz360-orange w-20 h-1" />
        </section>

        <section className="grid grid-cols-1 sm:grid-cols-2 sm:gap-4 w-3/4">
          <section className="hidden sm:block text-center text-xl md:text-2xl font-medium">
            <h1>Virtuální prohlídka</h1>
            <h1>
              pro <span className="text-cz360-green-300">Google Maps a Mapy . cz</span>
            </h1>
          </section>

          <section className="hidden sm:block text-center text-xl md:text-2xl font-medium">
            <h1 className="text-cz360-green-300">Matterport</h1>
            <h1>Plocha nemovitosti</h1>
          </section>

          <div className="max-w-[28.75rem] pt-10 space-y-6">
            <div className="sm:hidden pb-10 text-center text-xl md:text-2xl font-medium">
              <h1>Virtuální prohlídka</h1>
              <h1>
                pro <span className="text-cz360-green-300">Google Maps a Mapy . cz</span>
              </h1>
            </div>

            <PricingCard name="Malá -" description="do 7 bodů -"  price="2999" />
            <PricingCard name="Střední -" description="do 15 bodů -" price="4499" />
            <PricingCard name="Velká -" description="více než 15 bodů -" price="kontakujte nás" />
          </div>

          <div className="max-w-[28.75rem] pt-10 space-y-6">
            <div className="sm:hidden pb-10 text-center text-xl md:text-2xl font-medium">
              <h1 className="text-cz360-green-300">Matterport</h1>
              <h1>Plocha nemovitosti</h1>
            </div>

            <PricingCard name="Malá -" description="do 50 m<sup>2</sup> -" price="2999" />
            <PricingCard name="Střední -" description="50-150 m<sup>2</sup> -" price="4499" />
            <PricingCard name="Velká -" description="nad 150 m<sup>2</sup> -" price="kontakujte nás" />
          </div>
        </section>

        <section className="py-12 sm:mt-24">
          <CustomButton
            variant="orange"
            classes="w-60 h-12 text-xl"
            onClick={() => {
              navigate('#Contact')
            }}
          >
            Kontaktujte nás
          </CustomButton>
        </section>
      </div>
    </div>
  )
}

export default Pricing
