import CustomButton from '../global/CustomButton'
import DrawingHome from '../../assets/img/Drawing-Home.png'
import { useNavigate } from 'react-router-dom'

const Home = () => {
  const navigate = useNavigate()

  return (
    <section className="h-screen w-screen pt-[5.5rem] px-10 lg:px-16 xl:px-24 flex flex-col-reverse md:flex-row  justify-around md:justify-start items-center overflow-hidden">
      <div className="py-8 md:py-0">
        <div className="text-3xl md:text-6xl lg:text-7xl xl:text-8xl font-gangster font-semibold">
          <h1>Virtuální</h1>
          <div className="flex items-end">
            <div className="w-20 md:w-10 lg:w-28 h-0.5 mr-3 mb-3 bg-cz360-green-100" />
            <h1>prohlídky</h1>
          </div>
        </div>

        <p className="pt-8 text-base lg:text-xl w-full md:w-4/5">
          Díky virtuální prohlídce si mohou svou návštěvu naplánovat a vidět jak to u vás vypadá ještě než k vám vyrazí.
        </p>

        <div className="pt-12">
          <CustomButton
            variant="orange"
            classes="w-full sm:w-80 h-12 text-xl"
            onClick={() => {
              navigate('#Products')
            }}
          >
            Podívejte se kde už jsme byli
          </CustomButton>
        </div>
      </div>

      <img src={DrawingHome} alt="Home Page button" />
    </section>
  )
}

export default Home
