import React from 'react'

import CopyrightIcon from '../../assets/icons/Copyright.svg'

const Footer = () => {
  return (
    <div className="py-2 sm:py-5 px-4 sm:px-8 md:px-[8.5rem] flex justify-between bg-black text-xs sm:text-sm md:text-base font-normal text-white">
      <section className="space-x-[1.125rem] flex items-center">
        <img src={CopyrightIcon} alt="All rights reserved to cz360" />
        <p>cb360 2023</p>
      </section>

      <section className="flex items-center space-x-2 sm:space-x-[2.5rem]">
        <div className="space-x-[0.625rem] flex items-center">
          <div className="w-[1.125rem] h-[1.125rem] rounded-full bg-cz360-brown-500" />
          <a href="">Privacy Policy</a>
        </div>

        <div className="space-x-[0.625rem] flex items-center">
          <div className="w-[1.125rem] h-[1.125rem] rounded-full bg-cz360-brown-500" />
          <a href="">Therms of Use</a>
        </div>
      </section>
    </div>
  )
}

export default Footer
