import React, { useState } from 'react'

type PricingCardProps = {
  name: string
  description: string
  price?: string
}

// .. In case price would have ' ' space in it, each space will be new line

const PricingCard: React.FC<PricingCardProps> = ({ name, description, price = 'XXXX,-' }) => {
  return (
    <div className="w-full flex flex-wrap grid-rows-2 text-cz360-green-800">
      <section className="flex-grow font-semibold text-xl md:text-2xl">{name}</section>

      <section className="flex items-start space-x-2 w-full">
        <div
          className="font-medium w-fit text-base sm:text-lg md:text-xl"
          dangerouslySetInnerHTML={{ __html: description }}
        />
        <div className="mb-4 md:mb-2 border-b-[3px] flex-grow border-dotted border-cz360-dark">&nbsp;</div>
        <div className="font-extrabold text-base sm:text-xl md:text-2xl text-cz360-orange">
          {price.split(' ').map((word, idx) => (
            <p key={word + '_' + idx}>{word}</p>
          ))}
        </div>
      </section>
    </div>
  )
}

export default PricingCard
