import React, { useState } from 'react'

type GalleryCardProps = {
  name: string
  imgName: string
}

const GalleryCard: React.FC<GalleryCardProps> = ({ name, imgName }) => {
  const [imgSource, setImgSource] = useState<string>()

  import(`../../assets/img/gallery/${imgName}`).then((image) => setImgSource(image.default))

  return (
    <div className="group relative overflow-hidden w-full h-auto bg-black bg-opacity-40">
      <img
        src={imgSource}
        alt={'Image for ' + name}
        className="group-hover:scale-110 duration-200 ease-in-out w-full h-auto"
      />

      <div className="bottom-0 right-0 left-0 top-0 absolute bg-black z-10 bg-opacity-30 p-4 md:p-12 opacity-0 group-hover:opacity-100 duration-150 ease-in-out flex flex-col justify-end items-center">
        <div className="flex space-x-4">
          <h1 className="font-gangster text-base sm:text-xl md:text-3xl lg:text-5xl text-left text-white">{name}</h1>
          {/*  */}
        </div>

        <button></button>
      </div>
    </div>
  )
}

export default GalleryCard
