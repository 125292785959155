import React from 'react'
import GalleryCard from '../global/GalleryCard'

const images = [
  { name: 'Kavárna Sladký Život', imgName: 'KvarnaSladkyZivot.png', link: 'https://www.google.com/maps/place/Kav%C3%A1rna+Sladk%C3%BD+%C5%BDivot/@50.1059352,14.3731916,3a,75y,293.74h,81.62t/data=!3m7!1e1!3m5!1sAF1QipMEQI7gclQXSSQLxc5q50B-8sqPAh23HIv_YbY1!2e10!3e11!7i6080!8i3040!4m9!3m8!1s0x470b9506d997e2bb:0x795743b003ce8631!8m2!3d50.1059!4d14.3731893!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11h5v4_zs4?coh=205409&entry=ttu' },
  { name: 'Cowo CB', imgName: 'CowoCB.png', link: 'https://www.google.com/maps/place/Cowo+CB/@48.9730806,14.4741305,3a,75y,311.17h,80.58t/data=!3m8!1e1!3m6!1sAF1QipNXm02vcWTBv1lW7hrkl_rig1JpnwI4T77ovnc!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipNXm02vcWTBv1lW7hrkl_rig1JpnwI4T77ovnc%3Dw203-h100-k-no-pi-20-ya78.00001-ro-0-fo100!7i6080!8i3040!4m9!3m8!1s0x47734fb646ee0f95:0xe3ead05e4bf7f347!8m2!3d48.9730632!4d14.4740717!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F11cn7x2wcr?coh=205409&entry=ttu' },
  { name: 'Hotel La Grange, Courmayeur', imgName: 'HotelLaGrange.png', link: 'https://www.google.com/maps/place/Hotel+la+Grange/@45.8161204,6.9607333,3a,75y,140h,90t/data=!3m8!1e1!3m6!1sAF1QipMCA6B6GfYs6UyS5-wegtYShTtyBVgFkb-AJ96C!2e10!3e11!6shttps:%2F%2Flh5.googleusercontent.com%2Fp%2FAF1QipMCA6B6GfYs6UyS5-wegtYShTtyBVgFkb-AJ96C%3Dw203-h100-k-no-pi-0-ya183.25-ro0-fo100!7i6080!8i3040!4m12!3m11!1s0x478947baac624d07:0x459fc5ba219eef73!5m2!4m1!1i2!8m2!3d45.8161194!4d6.9607775!10e5!14m1!1BCgIgARICCAI!16s%2Fg%2F1tql3nvs?coh=205409&entry=ttu' },
]

const Gallery = () => {
  return (
    <section id="Products" className="w-full grid grid-cols-3 bg-cz360-dark">
      {images.map((img, idx) => (
        <div
          key={img.imgName + '_' + idx}
          className="tw-w-full overflow-hidden rounded hover:shadow-2xl hover:z-10 relative"
        >
          <a href={img.link} target="_blank" rel="noopener noreferrer">
            <GalleryCard name={img.name} imgName={img.imgName} />
          </a>
        </div>
      ))}
    </section>
  )
}

export default Gallery
