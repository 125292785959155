import React, { useRef, useState } from 'react'

import { SubmitHandler, useForm } from 'react-hook-form'
import CustomButton from '../global/CustomButton'
import ReCAPTCHA from 'react-google-recaptcha'

import DrawingFlow from '../../assets/img/Drawing-Flow.png'
import InstagramIcon from '../../assets/icons/Instagram.svg'
import FacebookIcon from '../../assets/icons/Facebook.svg'
import EmailIcon from '../../assets/icons/Email.svg'
import PhoneIcon from '../../assets/icons/Phone.svg'
import emailjs from '@emailjs/browser'

type FormInputs = {
  name: string
  phone: string
  email: string
  msg: string
}

const ContactUs: React.FC = () => {
  const siteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY
  const [loading, setLoading] = useState(false)

  const captchaRef = useRef<any>(null)
  const {
    register,
    handleSubmit,
    reset: resetFormValues,
    formState: { errors },
  } = useForm<FormInputs>()

  // .. Handlers
  const onSubmit: SubmitHandler<FormInputs> = async (data) => {
    if (captchaRef.current && !loading) {
      const token = captchaRef.current.getValue()

      if (token) {
        try {
          setLoading(true)

          await emailjs.send('service_fod3vv2', 'template_lejq9ba', data, {
            publicKey: 'PL5em-noCl7hDHehP',
          })

          alert('Email byl úspěšně odeslán!')
        } catch (e) {
          console.error(e)
          alert('Nepodařilo se odeslat email, prosím kontaktujte info@cb360.cz adresu napřímo.')
        } finally {
          setLoading(false)
          resetFormValues()
        }
      }
    } else {
      alert('Nepodařilo se ověřit zda nejste robot!')
    }

    if (captchaRef.current) {
      captchaRef.current.reset()
    }
  }

  // .. Form validation regex
  const namePattern = new RegExp(/^.{2,64}$/s)
  const phonePattern = new RegExp(/[+]?[0-9]+/gi)
  const emailPattern = new RegExp(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/)
  const msgPattern = new RegExp(/^.{1,512}$/s)

  // .. Classes
  const inputBaseClasses =
    'py-[1.125rem] px-3 w-full placeholder:text-gray-600 border-[3px] border-cz360-brown-300 rounded focus:outline-[3px] outline-cz360-orange'
  const textAreaBaseClasses =
    'p-3 w-full mt-0 md:mt-4 placeholder:text-gray-600 border-[3px] border-cz360-brown-300 rounded focus:outline-[3px] outline-cz360-orange resize-none'

  return (
    <div
      id="Contact"
      className="flex flex-col md:flex-row w-full px-12 lg:px-24 xl:px-32 items-start pb-16 max-xl:gap-10"
    >
      <section className="w-full xl:w-2/5">
        <h1 className="text-3xl font-gangster font-bold pt-12 md:pt-0 text-center md:text-left">Kontakty</h1>

        <section className="grid grid-cols-2 mt-10 gap-6">
          <div className="flex space-x-6 items-center justify-start">
            <img src={InstagramIcon} alt="Instagram contact Icon" className="w-8 md:w-[2.625rem]" />
            <a href="" className="font-semibold text-base lg:text-lg">
              @cb360.cz
            </a>
          </div>
          <div className="flex space-x-6 items-center justify-start">
            <img src={FacebookIcon} alt="Facebook contact Icon" className="w-8 md:w-[2.625rem]" />
            <a href="" className="font-semibold text-base  lg:text-lg">
              cb360.cz
            </a>
          </div>
          <div className="flex space-x-6 items-center justify-start">
            <img src={PhoneIcon} alt="Phone contact Icon" className="w-8 md:w-[2.625rem]" />
            <a href="" className="font-semibold text-base  lg:text-lg">
              +420 399 499 295
            </a>
          </div>
          <div className="flex space-x-6 items-center justify-start">
            <img src={EmailIcon} alt="Email contact Icon" className="w-8 md:w-[2.625rem]" />
            <a href="" className="font-semibold text-base  lg:text-lg">
              info@cb360.cz
            </a>
          </div>
        </section>

        <img src={DrawingFlow} alt="Flow of thoughts drawing" className="w-full h-auto mt-12 mb-16" />
      </section>

      <section className="flex md:hidden xl:flex space-x-7 items-end w-1/2 max-lg:self-center md:w-1/5 mt-4 justify-center">
        <div className="rounded bg-cz360-green-300 w-10 h-px mb-1" />
        <h1 className="text-sm font-semibold font-gangster">nebo</h1>
        <div className="rounded bg-cz360-green-300 w-10 h-px mb-1" />
      </section>

      <section className="w-full xl:w-2/5">
        <h1 className="text-3xl font-gangster font-bold text-center md:text-right">Napište nám</h1>

        <form
          className="grid grid-cols-2 grid-flow-row text-base gap-x-3 gap-y-[1.125rem] mt-10 "
          onSubmit={handleSubmit(onSubmit)}
        >
          <div className="col-span-2 block">
            <input
              className={`${inputBaseClasses}  ${
                errors.name ? ' border-cz360-red outline-cz360-red text-cz360-red placeholder:text-cz360-red' : ''
              }`}
              placeholder="Jméno"
              {...register('name', { required: true, pattern: namePattern })}
            />

            {errors.name && <span className="text-cz360-red text-xs">Nevhodný formát mena</span>}
          </div>

          <div className="max-lg:col-span-2">
            <input
              className={`${inputBaseClasses}  ${
                errors.phone ? ' border-cz360-red outline-cz360-red text-cz360-red placeholder:text-cz360-red' : ''
              }`}
              placeholder="Telefonní číslo."
              type="number"
              inputMode="numeric"
              {...register('phone', { required: true, pattern: phonePattern })}
            />

            {errors.phone && <span className="text-cz360-red text-xs">Nevhodný formát telefonného čísla</span>}
          </div>

          <div className="max-lg:col-span-2">
            <input
              className={`${inputBaseClasses}  ${
                errors.email ? ' border-cz360-red outline-cz360-red text-cz360-red placeholder:text-cz360-red' : ''
              }`}
              placeholder="E-mail"
              {...register('email', { required: true, pattern: emailPattern })}
            />

            {errors.email && <span className="text-cz360-red text-xs">Nevhodný formát e-mailu</span>}
          </div>

          <div className="col-span-2">
            <textarea
              className={`${textAreaBaseClasses}  ${
                errors.msg ? ' border-cz360-red outline-cz360-red text-cz360-red placeholder:text-cz360-red' : ''
              }`}
              placeholder="Vaše zpráva"
              maxLength={512}
              rows={6}
              {...register('msg', { required: true, pattern: msgPattern })}
            />

            {errors.msg && <span className="text-cz360-red text-xs">Nevhodný formát spravy</span>}
          </div>

          <div className="col-span-2 flex justify-end">
            {siteKey && <ReCAPTCHA sitekey={siteKey} ref={captchaRef} className="max-w-full" />}
          </div>

          <div className="col-span-2 flex justify-end">
            <CustomButton
              disabled={loading}
              variant="orange"
              type="submit"
              classes="w-fit h-10 !px-10 !text-xl justify-self-end self-end"
              onClick={() => {}}
            >
              Odeslat
            </CustomButton>
          </div>
        </form>
      </section>
    </div>
  )
}

export default ContactUs
